<template>
  <div class="pages-index">
    <div class="i-box">
      <div class="i-header"/>
      <!-- 卡片 -->
      <div class='new-card'>
        <div class="card-main">
          <div class='main-top'>可用额度(元)</div>
          <div class='main-number'>{{ $filters.formatMoney(creditAmt, 2) }}</div>
          <div class="main-des">{{ stateDes }}</div>

          <!-- 提额     -->
          <div class="main-btn-box" v-if="creditState===5 && isWithdrawal===0">
            <div class="box-left" @click='toWithdraw'>提额</div>
            <div class="box-right" @click="JumpLink('/loan')">{{ stateStr }}</div>
          </div>

          <div v-else>
            <div class="main-btn-dis" v-if="creditState===1 || creditState===3 || creditState===10 || creditState===9 || creditState===6 || creditState===7 || creditState===11">
              <span>{{ stateStr }}</span>
            </div>
            <div class="main-btn" v-else @click="goCard">{{ stateStr }}</div>
          </div>

        </div>
      </div>
    </div>
    <!-- 是否存在账单 -->
    <div class="i-bill" v-if="backdate" @click="JumpLink('/bill')">
      <div class="bill-box">
        <div class='box-l'>
          <div class="l-look-bill">查账还款</div>
          <div class="l-des-bill">
            <span class="des-bill-date">{{ backdate }}</span>
            应还款
            <span class="des-bill-number">{{ amount }}</span>
          </div>
        </div>
        <div class='box-r'></div>
      </div>
    </div>

    <!-- banner -->
    <div class="i-banner">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="item in bannerList" :key="item.id" @click="JumpLink(item)">
          <van-image class="banner-img" :src="item.url"></van-image>
        </van-swipe-item>
      </van-swipe>
    </div>

    <!-- 底部 -->
    <div class="i-footer">
      <div class="footer-list">
        <div class="l-line">
          <img src='./img/footer-f.png'/>
          <div class="line-text">专业合规</div>
        </div>
        <div class="l-line">
          <img src='./img/footer-s.png'/>
          <div class="line-text">息费透明</div>
        </div>
        <div class="l-line">
          <img src='./img/footer-t.png'/>
          <div class="line-text">安全保障</div>
        </div>
      </div>
      <!-- 联系客服 -->
      <a href="tel:19557109374" class="footer-tel">
        <i></i>
        <span>联系客服</span>
      </a>
      <div class="footer-text">理性借款 合理消费</div>
    </div>
    <!--签署协议弹框-->
    <template v-if="seeDialog">
      <Agree-popup :isShow="seeDialog" @changeStatus="changeStatus"/>
    </template>

    <!--输入BD的客户编号-->
    <van-dialog close-on-click-overlay v-model:show="visible" @confirm="onSubmit" title="绑定客户经理">
      <div style="padding: 20px">
        <van-field
          v-model="bdNo"
          label="工号:"
          placeholder="请输入客户经理编号"
          input-align="right"
          type="number"
        />
        <van-field
          v-model="partnerName"
          label="商户:"
          placeholder="请选择商户"
          input-align="right"
          readonly
          @click="showSelectPartner"
        />
      </div>
    </van-dialog>

    <van-popup v-model:show="bVisible" position="bottom">
      <van-picker
        value-key="realName"
        :columns="columns"
        @confirm="handleConfirm"
        @cancel="bVisible = false"
      />
    </van-popup>

  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import ls from 'local-storage'
import API from '@/server/api.js'
import { Toast } from 'vant'
import { Jump } from '@/utils/routh'
import AgreePopup from '@/components/AgreePopup/index.vue'
import { useRouter } from 'vue-router'

export default {
  components: { AgreePopup },
  setup () {
    const state = reactive({
      stock: null, // 用户手机号授权
      seeDialog: false,
      creditState: null,
      creditAmt: '200000',
      creditNo: '',
      userPhone: '',
      isLoading: false,
      protocolNeed: null,
      loanAmount: '',
      backdate: '',
      amount: '',
      loanNo: '',
      isWithdrawal: null,
      loanModelList: [],

      stateStr: '', // btn按钮
      stateDes: '',
      realNameState: 0, // 是否实名
      otherInfo: null,
      bannerList: [],
      showDialog: false,

      visible: false,
      bdNo: '',

      bVisible: false,
      partnerNo: '',
      partnerName: '',

      columns: [
        {
          partnerNo: '830110961318484844544',
          partnerName: '南宁市恩喜医疗美容有限公司'
        },
        {
          partnerNo: '830143044441767575552',
          partnerName: '测试222'
        }
      ]
    })

    const Router = useRouter()

    // 子传父（协议弹框传过来的seeDialog）
    const changeStatus = async (val) => {
      state.seeDialog = val.seeDialog
      await init()
      await initSignProtocol()
    }

    // 首页初始化
    onMounted(async () => {
      state.userPhone = JSON.parse(ls.get('cellPhone'))
      state.stock = JSON.parse(ls.get('stock'))

      await init() // 初始化信息
      await initSignProtocol() // 是否展示协议
      await initMerchantList() // 初始化商户列表
    })

    const initMerchantList = async () => {
      try {
        const res = await API.initMerchantList({})
        if (res.success) {
          console.log(res.data, '==')
          state.columns = res.result
        }
      } catch (err) {
        console.log(err)
      }
    }

    // 是否需要签署协议
    const initSignProtocol = async () => {
      try {
        TDAPP.onEvent('签署协议', { path: 'index' })
        const params = { phone: state.userPhone }
        const res = await API.issignProtocol({ ...params })
        if (res.success) {
          const { need } = res.data
          state.protocolNeed = need
        }
      } catch (err) {
        console.log(err)
      }
    }

    // 提额
    const toWithdraw = async () => {
      if (state.isWithdrawal) {
        Toast.fail('您已经提额过了哦！')
        return false
      } else {
        TDAPP.onEvent('提额', { path: 'index' })
        await API.addMoney()
        Jump('/infoNavigation', { key: 2 })
      }
    }

    // 初始化
    const init = async () => {
      try {
        const res = await API.isUser()
        if (res.success) {
          const {
            userId,
            creditNo,
            otherInfo,
            creditState,
            isWithdrawal,
            creditAmt,
            loanNo,
            loanModelList,
            productName,
            stateStr,
            stateDes,
            realNameState,
            bannerUrl
          } = res.data

          localStorage.setItem('userId', userId)
          localStorage.setItem('creditNo', creditNo)
          if (loanNo) localStorage.setItem('loanNo', loanNo)
          state.loanAmount = creditAmt
          state.stateStr = stateStr
          state.stateDes = stateDes
          if (realNameState) state.realNameState = realNameState
          if (loanModelList != null && loanModelList.length > 0) {
            state.amount = loanModelList[0].planAmt
            state.backdate = loanModelList[0].planDateStr
          }
          if (otherInfo != null) {
            state.otherInfo = otherInfo
          }
          state.bannerList = bannerUrl
          state.loanModelList = loanModelList

          state.creditState = creditState
          state.userId = userId
          state.isWithdrawal = isWithdrawal
          state.creditAmt = creditAmt || '200000'
          state.productName = productName
        }
      } catch (err) {
        console.log('初始化用户授信报错')
      }
    }

    // 卡片btn
    const goCard = () => {
      const { realNameState, protocolNeed } = state

      // 没有签署协议，弹框
      if (protocolNeed) {
        state.seeDialog = true
        return
      }
      // 签署过了，直接跳到身份认证
      if (!realNameState) JumpLink('/auth')
      else {
        // 预授信0
        switch (state.creditState) {
          case 0:
            state.visible = true
            break
          case 1:
            break
          case 2:
            JumpLink('/infoNavigation', { key: 1 })
            break
          case 4:
            JumpLink('/uploadImg')
            break
          case 5:
            JumpLink('/loan')
            break
        }
      }
    }

    // 链接跳转
    const JumpLink = (url, params) => {
      if (!params) params = {}
      switch (url) {
        case '/bill':
          TDAPP.onEvent('查看账单', { path: 'index' })
          localStorage.setItem('loanModelList', JSON.stringify(state.loanModelList))
          break
      }
      Jump(url, params)
    }

    // 绑定客户经理弹框
    const onSubmit = async () => {
      if (!state.bdNo) {
        Toast('请输入客户经理编号')
        return false
      }

      try {
        const data = {
          bdNo: state.bdNo,
          partnerNo: state.partnerNo,
          partnerName: state.partnerName,
          productName: 'LRD_DAI',
          expirationDate: '322',
          userId: state.userId
        }
        const res = await API.scanBd(data)
        console.log(res, '绑定客户经理')
        if (res.success) {
          Toast.success('操作成功')
          window.location.reload()
        }
      } catch (err) {
        Toast(err.message)
        console.log(err.message)
      }
    }

    const showSelectPartner = () => {
      state.bVisible = true
    }

    const handleConfirm = (v) => {
      console.log(v, '内容')
      const { realName, partnerNo } = v
      state.partnerNo = partnerNo
      state.partnerName = realName
      state.bVisible = false
    }

    return {
      ...toRefs(state),

      toWithdraw,
      changeStatus,
      goCard,

      JumpLink,
      onSubmit,
      showSelectPartner,
      handleConfirm
    }
  }
}
</script>

<style lang="less" src="./index.less" scoped>
</style>
